.designSection {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.designSectionContainer {
  width: 100%;
  overflow: hidden;

  padding-bottom: 24px;
  margin: 0;

  max-width: 1012px;

  @media (min-width: 769px) {
    margin: 0 24px;
    padding-bottom: 50px;
  }
}

.designSection * h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
  white-space: nowrap;

  @media (min-width: 769px) {
    font-size: 24px;
  }

  @media (min-width: 1201px) {
    font-size: 32px;
  }
}

.designSection * p {
  color: #4c4c4c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;

  @media (min-width: 769px) {
    font-size: 18px;
  }
}

.designSectionHeader {
  display: flex;
  align-items: center;
  gap: 24px;

  margin-left: 20px;
  margin-right: 10px;

  @media (min-width: 769px) {
    margin: 0;
    flex-direction: row;
  }
}

.designSectionHeaderTileContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  @media (min-width: 769px) {
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }
}

.designSectionHeaderDescription {
  display: none;
  @media (min-width: 1201px) {
    display: block;
  }
}

.designSectionHeaderShort {
  display: block;
  @media (min-width: 1201px) {
    display: none;
  }
}

.designSectionBody {
  display: flex;
  gap: 10px;
  overflow: auto;
  margin-top: 14px;
  padding: 0 20px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 769px) {
    padding: 0;
    gap: 28px;
  }

  @media (min-width: 1021px) {
    padding: 0;
    gap: 56px;
  }

  @media (min-width: 1201px) {
    padding: 0;
    gap: 38x;
  }
}

.designSectionBody::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
