.homeMyeventWrapper {
  display: flex;
  justify-content: center;
  background-color: #f5efed;
  padding: 40px 16px;

  @media (min-width: 1096px) {
    padding: 156px 0;
  }
}

.homemyeventcontainer {
  width: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 1096px) {
    flex: 0 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
  }
}

.homeContentContainer {
  flex: 1 1 auto;
  width: 100%;

  @media (min-width: 1096px) {
    flex: 0 0 502px;
    width: 502px;
  }
}

.homeContentContainer > h2 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;

  @media (min-width: 769px) {
    font-size: 32px;
  }
}

.homeMyeventButtonContainer {
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (min-width: 769px) {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.homeContentContainer > p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (min-width: 769px) {
    font-size: 20px;
  }
}

.homeImageContainer {
  flex: 1 1 auto;
  width: 100%;
  text-align: center;
  @media (min-width: 1096px) {
    flex: 0 0 478px;
    width: 478px;
  }
}
