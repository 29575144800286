.homeExploreBody {
  padding: 0;
}

.homeMasonary {
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 769px) {
    display: grid;
    grid-gap: 11px;
    grid-template-columns: repeat(3, 30%);
    direction: ltr;
  }

  @media (min-width: 1069px) {
    display: grid;
    grid-gap: 11px;
    grid-template-columns: repeat(3, 245px);
    grid-auto-rows: 245px;
    direction: ltr;
  }
}
