.fullscreenTakeover {
  background-color: red;
  height: 100%;
}

.homeTitleWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  /*
  margin-bottom: 48px;
  margin-top: 16px;
*/

  @media (min-width: 1012px) {
    min-height: 620px;
    align-items: center;
    /*
    margin-bottom: 125px;
    margin-top: 70px;
    */
  }
}

.homeTitleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  align-items: center;

  @media (min-width: 1012px) {
    max-width: 1012px;
    flex: 1 1 auto;

    gap: 60px;
  }
}

.homeTitleContainer > div > h1 {
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 127.778% */
  letter-spacing: 0.36px;
  margin: 0;
}

.homeTitleContainer > div > h2 {
  color: #43546f;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 180% */
  letter-spacing: 0.2px;
  margin: 0;
}

.homeTitleContentContainer {
  flex: 1 1 auto;
  width: 100%;

  display: flex;

  gap: 24px;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1012px) {
    gap: 106px;
    flex-direction: row;
    align-items: stretch;
  }
}

.homeTitleContentRevealer {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 16px;

  @media (min-width: 1012px) {
    margin-top: 0;
  }
}

.homeTitleContentUploader {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  margin: 0 24px;

  @media (min-width: 1012px) {
    margin: 0;
  }
}

.homeTitleContentUploader > h2 {
  color: #212d40;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;

  @media (min-width: 1012px) {
    font-size: 26px;
  }
}

.homeTitleContentUploader > p {
  color: #838b93;

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.homeTitleContentUploader > p > a {
  color: #838b93;

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: underline;
}

.homeTitleContentDropZone {
  flex: 1 1 auto;

  margin-top: 22px;
  margin-bottom: 20px;

  border-radius: 0;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 24px;

  @media (min-width: 1012px) {
    border-radius: 20px;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
    align-items: center;
  }
}

.homeTitleContentDropZone > p {
  color: #454545;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.homeTitleTryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1012px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.homeTitleTryContainer * p {
  margin: 0;
  padding: 0;

  color: #454545;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.homeTitlePreselectContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.homeTitlePreselectImage {
  border-radius: 6px;
  cursor: pointer;
}

.homeTitleDestop {
  display: none;
  @media (min-width: 1012px) {
    display: block;
  }
}

.homeTitleMobile {
  display: block;
  @media (min-width: 1012px) {
    display: none;
  }
}

.homeTitleMobile > h1 {
  margin: 0 20px;
  color: #212d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.2px;
}

.homeTitleMobile > h2 {
  margin: 0 20px;
  color: #212d40;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  letter-spacing: 0.15px;
}

.homeHiddenUploadInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
